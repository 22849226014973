// Import dependencies
import React from "react"
import { Row, Col } from "antd"
import { navigate } from "gatsby"
import { Form } from "antd"
import { Button } from 'antd';

// Import components
import InputField from "../form/input/inputField"
import GeneralButton from "../form/buttons/GeneralButton"
import Select from "../form/select/select";

// Import styles
import "../../pages/step.css"
import "../../pages/recycle.css"
import {Separator} from "../separators/separator";
import {PageContainer} from "../../styled-components/UILibrary";

import {
  CreditPreference,
  CreditPreferenceInputs} from "../../data/inputFields";

import { getFirebase } from "../../api/firebase";
/*
    Form for the Step 1 of the checkout process page
*/
class Step1Form extends React.Component {
  constructor(props) {
    super(props);
  }

  handleSubmit = e => {
    e.preventDefault()
    let codePromo = this.props.codePromo;
    this.props.form.validateFields(err => {
      if (!err) {
        this.props.recordOrderDetails()
        navigate("/step-2",{
          state: {
            codePromoPrev: codePromo,
            creditPreference: this.props.creditPreference
          }
        })
      }
    })
  }

  render() {
    const { form } = this.props
    return (
      <Form
        onSubmit={this.handleSubmit}
      >
      <div className="step_tittle">
        Contact
      </div>
        <div className="step_container_flex">
            <InputField
              placeholder="First name"
              form={form}
              id="first-name"
              error="Please enter valid first name."
              isRequired
              type="string"
              initialValue={this.props.account_info.first_name}
              onChange={e => this.props.onChange("first_name", e)}
            />
            <InputField
              placeholder="Last name"
              form={form}
              id="last-name"
              error="Please enter valid last name."
              isRequired
              type="string"
              initialValue={this.props.account_info.last_name}
              onChange={e => this.props.onChange("last_name", e)}
            />
        </div>
        <div className="step_container_flex">
            <InputField
              placeholder="Email"
              form={form}
              id="email"
              error="Please enter valid email."
              isRequired
              type="email"
              initialValue={this.props.account_info.email}
              onChange={e => this.props.onChange("email", e)}
            />
            <InputField
              placeholder="Contact number"
              form={form}
              id="phone"
              error="Please enter valid contact number."
              isRequired
              type="string"
              initialValue={this.props.account_info.phone}
              onChange={e => this.props.onChange("phone", e)}
              phoneNumber
            />
          </div>
            

        <div className="step_tittle">
        Payment
        <p>Get paid on the 3rd day after collection, or within 24h if you’re a member.</p>
      </div>
      <div className="checkout_payment_container checkout_select_transfer">
        <Select
          isRequired
          id="credit-options"
          label="How We’ll Pay You"
          placeholder="Select"
          error="Please select credit options."
          form={form}
          data={CreditPreference}
          onChange={this.props.updateCreditPreference}
          whiteLabel
          initialValue={this.props.transfer_info.UserCreditPreference ? this.props.transfer_info.UserCreditPreference : undefined}
        />
      </div>

      <div className="step_container_flex">
      {/* Extra input fields depending on Select value */}
      {CreditPreferenceInputs[this.props.creditPreference]
        ? CreditPreferenceInputs[this.props.creditPreference].map(
          (item, i) => (
            Array.isArray(item.options)
              ? <Select
                isRequired
                id={item.placeholder}
                label={item.placeholder}
                placeholder="Select"
                error={item.error}
                form={form}
                data={item.options}
                initialValue={this.props.transfer_info.creditDetails["Beneficiary bank"] ? this.props.transfer_info.creditDetails["Beneficiary bank"] : null}
                onChange={value =>
                  this.props.updateCreditDetails(item.placeholder, value, true)
                }
              />
              : <InputField
                key={item.placeholder.split(" ") .join("_") .toLowerCase()}
                isRequired
                id={item.placeholder}
                error={item.error}
                type={item.type}
                form={form}
                placeholder={item.placeholder}
                onChange={e =>
                  this.props.updateCreditDetails(item.placeholder, e)
                }
                phoneNumber={item.phoneNumber}
                initialValue={this.props.transfer_info.creditDetails[item.placeholder] && this.props.transfer_info.creditDetails[item.placeholder]}
              />
          )
        )
        : null}
        </div>

        <div className="checkout_footer_mobile">
            <GeneralButton
                title="Next"
                type="primary"
                fit
                htmlType="submit"
                margin={{marginLeft: "auto",display:"block"}}
            />
          <span className="cart_checkout_header_switch" onClick={this.props.onMobileTrigger}>{!this.props.mobileShow ? "Show order summary" : "Return to checkout"}</span>          
        </div>

      </Form>
    )
  }
}

export default Form.create({ name: "checkout-1" })(Step1Form)
