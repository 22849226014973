// Import dependencies
import React from "react";
import { Form } from "antd";
import {navigate} from "gatsby";

// Import components
import Layout from "../layouts/layout";
import SEO from "../components/seo/seo";
import { PageContainer } from "../styled-components/UILibrary";
import StepsIndicator from "../components/checkout/stepsIndicator";
import TitleSection from "../components/sections/titleSection";
import Step1Form from "../components/checkout/step1Form";
import SectionSeparator from "../components/separators/sectionSeparator";
import HeaderSeparator from "../components/separators/headerSeparator";
import GeneralCartItem from "../components/cart/genCartItem";
import CartItem from "../components/cart/cartItem";
import InputField from "../components/form/input/inputField"
import GeneralButton from "../components/form/buttons/GeneralButton"
import { ParagraphText } from "../styled-components/UILibrary";
import CheckoutCart from "../components/checkout/checkout_cart";
import StatisticsPopup from "../components/form/forms/statisticsPopup";
import Logo from "../assets/icons/logo_green_login.svg";
import { Link } from "gatsby"
import { pushOrderInformation } from "../redux/actions/checkoutAction";

// Import styles
import "./step.css";
import "./recycle.css";
import { Constants } from "../constants/Constants";

// Import redux
import { connect } from "react-redux";
import { pushAccountInformation } from "../redux/actions/checkoutAction";
import {getCartQuote} from "../util/cart";
import { challengesLoaded } from "../redux/actions/challengesAction"

// Import firebase
import { getFirebase } from "../api/firebase";
import {Separator} from "../components/separators/separator";

/*
    Step 1 of the checkout process pagea
*/
class Step1 extends React.Component {
  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this);
    this.togglePopup = this.togglePopup.bind(this);
    this.updatePromoCode = this.updatePromoCode.bind(this);
    this.applyPromoCode = this.applyPromoCode.bind(this);
    this.isBrowser = this.isBrowser.bind(this);
    this.updateCreditPreference = this.updateCreditPreference.bind(this);
    this.updateCreditDetails = this.updateCreditDetails.bind(this);
    const {
      location: { state },
    } = this.props;
    const {codePromoPrev} = state ? state : {};
    this.state = {
      account_info: {
        email: null,
        first_name: null,
        last_name: null,
        phone: null,
        mobileShow: false,
      },
      creditPreference: null,
      creditDetails: {},
      transfer_info: {
        UserCreditPreference: "",
        creditDetails: {}
      },
      popupOpen: false,
      popupContent: "",
      checkEmail: true,
      codePromoInput: "",
      codePromoPrev: codePromoPrev ? true : false,
      codePromo: codePromoPrev ? codePromoPrev : {code: "", value: 0},
      refealCodesArray: [],
    };
  }

  isBrowser = () => typeof window !== "undefined";

  componentWillMount() {
    if (this.isBrowser() && !this.props.loggedIn) {
      navigate("/login");
    }
  }

  togglePopup = (content) => {
    this.setState({
      popupOpen: !this.state.popupOpen,
      popupContent: content
    });
  }
  updatePromoCode = (e) => {
    this.setState({ codePromoInput: e.target.value });
  }

  applyPromoCode = () => {
    if (this.state.refealCodesArray.includes(this.state.codePromoInput)) {
      this.setState({ codePromo: {code: this.state.codePromoInput, value: 5}});
    } else {
      this.setState({ codePromo: {code: "", value: 0}});
    }
  }
  // Gets called on change of the form input fields
  onChange = (field, e) => {
    let temp = { ...this.state.account_info };
    temp[field] = e.target.value;
    this.setState({ account_info: temp });
  };

  // Sends data from this form to redux
  recordOrderDetails = () => {
    this.props.dispatch(pushAccountInformation(this.state.account_info));
    this.props.dispatch(
      pushOrderInformation({
        credit_preference: this.state.creditPreference,
        credit_details: this.state.creditDetails,
      })
    );
  };

  updateCreditPreference = value => {
    this.setState({ creditPreference: value });
  };

  updateCreditDetails = (item, eventOrValue, asValue = false) => {
    let temp = this.state.creditDetails;
    const key = item
      .split(" ")
      .join("_")
      .toLowerCase();

    temp[key] = asValue ? eventOrValue : eventOrValue.target.value;

    this.setState({ creditDetails: temp });
  };
  // In case user is signed-in, fetch user data
  componentDidMount() {
//    if (getCartQuote(this.props.cart, this.props.generalCart) < 15) {
  if (!(this.props.cart.length + this.props.generalCart.length)) {
    navigate("/");
    }
    // Check if users is signed in
      // Fetch user data
      const app = import("firebase/app");
      const firestore = import("firebase/firestore");

      Promise.all([app, firestore]).then(([firebase]) => {
        if (this.props.userToken) {
          getFirebase(firebase)
            .firestore()
            .collection("users")
            .doc(this.props.userToken.uid)
            .get()
            .then(doc => {
              if (doc.exists) {
                this.setState({
                  account_info: doc.data().account_info,
                  transfer_info: doc.data().transfer_info,
                  creditPreference: doc.data().transfer_info.UserCreditPreference,
                  checkEmail: false
                });
              }
            })
            .catch(/* ERROR */);
        }

          getFirebase(firebase)
            .firestore().collection("refeal_codes").get().then((querySnapshot) => {
              querySnapshot.forEach((doc) => {
                this.state.refealCodesArray.push(doc.data().code);
              });
          }).then(() => {
            const refealCodeLink = this.props.params['*'] ? this.props.params['*'] : false;

                if (!this.state.codePromoPrev && refealCodeLink) {
                  if (this.state.refealCodesArray.includes(refealCodeLink)) {
                    this.setState({ codePromo: {code: refealCodeLink, value: 5}, codePromoInput: refealCodeLink});
                  }
                }

          });
      });

      const {fetchedCallback = _ => _} = this.props;  
      Promise.all([app, firestore])
          .then(([firebase]) =>
              getFirebase(firebase)
                  .firestore()
                  .collection("challenges")
                  .get()
                  .then(querySnapshot => {
                      if (Array.isArray(querySnapshot) && querySnapshot.length === 0)
                          fetchedCallback();
                      let challenges = []
                      querySnapshot.forEach(doc => {
                          // Get data
                          challenges = [...challenges, doc.data()]
                      });
                      this.props.dispatch(challengesLoaded(challenges));
  
                  })
          )
          .catch(fetchedCallback);
  

  }

  render() {
    const chevronRight = <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 10 10"><path d="M2 1l1-1 4 4 1 1-1 1-4 4-1-1 4-4"></path></svg>;
    return (
      <Layout noFooter noHeader stepPage checkoutWhite noWhatsapp>
        <SEO title="Checkout" />
          <StatisticsPopup popupOpen={this.state.popupOpen} togglePopup={this.togglePopup} content={this.state.popupContent}/>
          <div className="checkout_page_container" style={this.state.mobileShow ? {height: "100%"} : {}}>            
            <CheckoutCart
              collectionFee={0}
              codePromo={this.state.codePromo}
              updatePromoCode={this.updatePromoCode}
              applyPromoCode={this.applyPromoCode}
              togglePopup={this.togglePopup}
              mobileShow={this.state.mobileShow}
              onMobileTrigger={() => this.setState({mobileShow: !this.state.mobileShow})}
              codePromoLink={this.props.params['*'] ? this.props.params['*'] : ""}
            />
            {/* Process indicator */}
            <div className={!this.state.mobileShow ? "checkout_page_forms" : "checkout_page_forms checkout_page_forms_hide"}>
              <Link to="/" className="checkout_logo" >
                <Logo />
              </Link>
              <span className="step_indicator">
                <b>Contact</b>{chevronRight}Schedule{chevronRight}Add-ons{chevronRight}Review</span>
              <Step1Form
                mobileShow={this.state.mobileShow}
                onMobileTrigger={() => this.setState({mobileShow: !this.state.mobileShow})}
                account_info={this.state.account_info}
                checkEmail={this.state.checkEmail}
                recordOrderDetails={this.recordOrderDetails}
                onChange={this.onChange}
                locState={this.props.location.state}
                email={this.state.account_info.email}
                codePromo={this.state.codePromo}

                creditPreference={this.state.creditPreference}
                updateCreditPreference={this.updateCreditPreference}
                updateCreditDetails={this.updateCreditDetails}
                transfer_info={this.state.transfer_info}

              />         
              

              <div className="checkout_next_step">
                <div className="step_tittle">Schedule</div>
                <span>Edit</span>
              </div>
              <div className="checkout_next_step">
                <div className="step_tittle">Add-ons</div>
                <span>Edit</span>
              </div>
            </div>
          </div>

      </Layout>
    );
  }
}

// Connect redux to component
const mapStateToProps = state => ({
  cart: state.cart,
  generalCart: state.generalCart,
  userToken: state.user.userToken,
  loggedIn: state.user.isLoggedIn
});
export default connect(mapStateToProps)(Step1);
